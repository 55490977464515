.contact-main-container {
  color: #46434c;
  padding: 30px;
  margin: 50px 0 25vh 0;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.contact-main-container a {
  color: #46434c;
  font-size: 20px;
  text-decoration: none;
}

.contact-main-container a:hover {
  font-weight: bold;
}

.email {
  font-size: 20px;
}

@media screen and (max-width: 450px) {
  .contact-main-container {
    padding: 20px;
    width: 70vw;
  }

  .contact-main-container h1 {
    font-size: 1.5em;
  }

  .contact-main-container h3 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 350px) {
  .email {
    font-size: 16px;
  }

  .contact-main-container a {
    font-size: 16px;
  }
}
