.about-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #46434c;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.about-me {
  font-size: 18px;
  line-height: 1.5;
  width: 70vw;
  padding: 20px;
}

.skills-intro {
  margin-bottom: 2px;
}

.skills-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  margin-top: 25px;
  font-size: 18px;
}

.skills-names-list {
  list-style: none;
  -moz-column-count: 4;
  -moz-column-gap: 15px;
  -webkit-column-count: 2;
  -webkit-column-gap: 15px;
  column-count: 4;
  column-gap: 15px;
  text-align: left;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  justify-content: center;
  margin-bottom: 60px;
}

.indiv-logo {
  height: 100px;
  margin: 5px;
}
