.home-container {
  color: #46434c;
  width: 80vw;
  margin: 40px 0 50px 0;
}

.home-headshot {
  max-height: 200px;
  border-radius: 100%;
  box-shadow: 0 8px 32px 0 rgba(6, 9, 49, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.text-loop {
  font-weight: bold;
  font-size: 18px;
}

.homepage-blurb {
  width: 80vw;
}

.homepage-blurb p {
  font-weight: normal;
}

.home-link-about a {
  color: #46434c;
  text-decoration: none;
  font-size: 1.25em;
  transition: all 0.2s ease;
}

.home-link-about a:link {
  color: #46434c;
}

.home-link-about a:visited {
  color: #46434c;
}

.home-link-about a:hover {
  font-weight: bold;
}

/* media queries */

@media screen and (max-width: 650px) {
  .text-loop {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .text-loop {
    font-size: 20px;
  }
}

@media screen and (max-width: 350px) {
  .text-loop {
    font-size: 16px;
  }

  .home-h1 {
    font-size: 20px;
  }

  .homepage-blurb {
    font-size: 16px;
    margin-top: 40px;
  }
}
