.projects-container {
  color: #46434c;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.project-div {
  display: flex;
  background-color: white;
  align-items: center;
  width: 70vw;
  margin: 4% 2%;
  padding: 1.25rem;
  transition: all 0.3s ease;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.project-div:hover {
  transform: scale(1.02, 1.02);
}

.project-div img {
  height: 150px;
  max-width: 200px;
}

.project-text {
  width: 80%;
}

.project-text a {
  color: #ffff;
  text-decoration: none;
  margin-right: 10px;
  border-radius: 0.25rem;
  padding: 5px;
  background-color: #46434c;
}

.project-text a:hover {
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .project-div {
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .project-links {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: fit-content;
  }

  .project-links a {
    margin-top: 8px;
  }
}
