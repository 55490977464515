.sidenav {
  color: #46434c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
  align-items: flex-start;
}
.sidenav-hidden {
  display: none;
}

.sidenav a {
  color: #46434c;
}

.sidenav a:visited {
  color: #46434c;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #46434c;
}

.nav-menu {
  width: 250px;
  background-color: #fdb5c3;
  background-image: none;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu-active {
  right: 0;
  align-items: flex-start;
  width: 350px;
  z-index: 2;
  background-color: #fdb5c3;
  background-image: none;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
}

.nav-text svg {
  padding-right: 5px;
  font-size: 24px;
  color: #46434c;
}

.nav-text a {
  text-decoration: none;
  color: #46434c;
  font-size: 24px;
  width: 95%;
  display: flex;
  align-items: center;
}

.nav-text a:hover {
  font-weight: bold;
}

/* .resume-sidenav {
  padding-left: 3px;
} */

#resume-sidenav {
  padding: 0 3px;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fdb5c3;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 851px) {
  .sidenav {
    display: none;
  }
}
