.layout {
  background-size: cover;
  height: 100vh;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.nav-links-container {
  margin-right: 20px;
  display: flex;
}

.nav-links-container a {
  color: #46434c;
  text-decoration: none;
  transition: all 0.2s ease;
}

.nav-links-container a:link {
  color: #46434c;
}

.nav-links-container a:visited {
  color: #46434c;
}

.nav-links-container a:hover {
  font-weight: bold;
}

.nav-link {
  margin: 0 5px;
  font-size: 1.2em;
  padding-left: 7px;
  display: flex;
  align-items: center;
}

.contact-proj svg {
  padding-right: 5px;
}

.layout-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  display: flex;
  justify-content: center;
  /* position: fixed; */
  bottom: 0;
  /* width: 100vw; */
}

footer p {
  display: flex;
}

.footer-icon {
  font-size: 20px;
  margin-left: 5px;
  color: #46434c;
}

.footer a:link {
  color: #46434c;
}

.footer a:visited {
  color: #46434c;
}

@media screen and (max-width: 850px) {
  .nav-links-container {
    display: none;
  }
}
